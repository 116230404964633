<template>
  <!-- <client-only> -->
  <ul v-show="meta.total_pages > 1" :class="containerClass" v-if="!noLiSurround">
    <li v-if="firstLastButton" :class="[pageClass, firstPageSelected() ? disabledClass : '']">
      <a
        @click="selectFirstPage()"
        @keyup.enter="selectFirstPage()"
        :class="pageLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        v-html="firstButtonText"
      ></a>
    </li>

    <li
      v-else-if="!(firstPageSelected() && hidePrevNext)"
      :class="[prevClass, firstPageSelected() ? disabledClass : '']"
    >
      <a
        @click="prevPage()"
        @keyup.enter="prevPage()"
        :class="prevLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        v-html="prevText"
      ></a>
    </li>

    <li
      v-for="page in pages"
      :key="page.id"
      :class="[
        pageClass,
        page.selected ? activeClass : '',
        page.disabled ? disabledClass : '',
        page.breakView ? breakViewClass : '',
      ]"
    >
      <a v-if="page.breakView" :class="[pageLinkClass, breakViewLinkClass]" tabindex="0"
        ><slot name="breakViewContent">{{ breakViewText }}</slot></a
      >
      <a v-else-if="page.disabled" :class="pageLinkClass" tabindex="0">{{ page.content }}</a>
      <a
        v-else
        @click="handlePageSelected(page.index + 1)"
        @keyup.enter="handlePageSelected(page.index + 1)"
        :class="pageLinkClass"
        tabindex="0"
        >{{ page.content }}</a
      >
    </li>

    <li
      v-if="!(lastPageSelected() && hidePrevNext)"
      :class="[nextClass, lastPageSelected() ? disabledClass : '']"
    >
      <a
        @click="nextPage()"
        @keyup.enter="nextPage()"
        :class="nextLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        v-html="nextText"
      ></a>
    </li>

    <li v-else-if="firstLastButton" :class="[pageClass, lastPageSelected() ? disabledClass : '']">
      <a
        @click="selectLastPage()"
        @keyup.enter="selectLastPage()"
        :class="pageLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        v-html="lastButtonText"
      ></a>
    </li>
  </ul>

  <ul v-show="meta.total_pages > 1" :class="containerClass" v-else>
    <li>
      <a
        v-if="firstLastButton"
        @click="selectFirstPage()"
        @keyup.enter="selectFirstPage()"
        :class="[pageLinkClass, firstPageSelected() ? disabledClass : '']"
        tabindex="0"
        v-html="firstButtonText"
      ></a>
      <a
        v-else-if="!(firstPageSelected() && hidePrevNext)"
        @click="prevPage()"
        @keyup.enter="prevPage()"
        :class="[prevLinkClass, firstPageSelected() ? disabledClass : '']"
        tabindex="0"
        v-html="prevText"
      ></a>
      <template v-for="page in pages">
        <a
          v-if="page.breakView"
          :key="page.id"
          :class="[pageLinkClass, breakViewLinkClass, page.disabled ? disabledClass : '']"
          tabindex="0"
          ><slot name="breakViewContent">{{ breakViewText }}</slot></a
        >
        <a
          v-else-if="page.disabled"
          :key="page.id"
          :class="[pageLinkClass, page.selected ? activeClass : '', disabledClass]"
          tabindex="0"
          >{{ page.content }}</a
        >
        <a
          v-else
          :key="page.id"
          @click="handlePageSelected(page.index + 1)"
          @keyup.enter="handlePageSelected(page.index + 1)"
          :class="[pageLinkClass, page.selected ? activeClass : '']"
          tabindex="0"
          >{{ page.content }}</a
        >
      </template>
      <a
        v-if="!(lastPageSelected() && hidePrevNext)"
        @click="nextPage()"
        @keyup.enter="nextPage()"
        :class="[nextLinkClass, lastPageSelected() ? disabledClass : '']"
        tabindex="0"
        v-html="nextText"
      ></a>
      <a
        v-else-if="firstLastButton"
        @click="selectLastPage()"
        @keyup.enter="selectLastPage()"
        :class="[pageLinkClass, lastPageSelected() ? disabledClass : '']"
        tabindex="0"
        v-html="lastButtonText"
      ></a>
    </li>
  </ul>
  <!-- </client-only> -->
</template>

<script>
export default {
  name: 'fractal-pagination',
  props: {
    meta: {
      type: Object,
      default: function () {
        return {
          count: 0,
          current_page: 1,
          links: {
            next: null,
            previous: null,
            last: null,
            first: null,
          },
          per_page: 10,
          total: 0,
          total_pages: 0,
        };
      },
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevText: {
      type: String,
      default: 'Prev',
    },
    nextText: {
      type: String,
      default: 'Next',
    },
    breakViewText: {
      type: String,
      default: '…',
    },
    containerClass: {
      type: String,
      default: 'pagination b-pagination pagination-md',
    },
    pageClass: {
      type: String,
      default: 'page-item',
    },
    pageLinkClass: {
      type: String,
      default: 'page-link',
    },
    prevClass: {
      type: String,
      default: 'page-item',
    },
    prevLinkClass: {
      type: String,
      default: 'page-link',
    },
    nextClass: {
      type: String,
      default: 'page-item',
    },
    nextLinkClass: {
      type: String,
      default: 'page-link',
    },
    breakViewClass: {
      type: String,
    },
    breakViewLinkClass: {
      type: String,
    },
    activeClass: {
      type: String,
      default: 'active',
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    noLiSurround: {
      type: Boolean,
      default: false,
    },
    firstLastButton: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: 'First',
    },
    lastButtonText: {
      type: String,
      default: 'Last',
    },
    hidePrevNext: {
      type: Boolean,
      default: false,
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  computed: {
    selected: function () {
      return this.meta.current_page;
    },
    pages: function () {
      let items = {};
      if (this.meta.total_pages <= this.pageRange) {
        for (let index = 0; index < this.meta.total_pages; index++) {
          items[index] = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1,
          };
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);
        let setPageItem = (index) => {
          items[index] = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1,
          };
        };
        let setBreakView = (index) => {
          items[index] = {
            disabled: true,
            breakView: true,
          };
        };
        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }
        // 2nd - loop thru selected range
        let selectedRangeLow = 0;
        if (this.selected - 2 - halfPageRange >= 0) {
          selectedRangeLow = this.selected - 2 - halfPageRange;
        }
        let selectedRangeHigh = selectedRangeLow + this.pageRange + 1;
        if (selectedRangeHigh >= this.meta.total_pages) {
          selectedRangeHigh = this.meta.total_pages - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }
        for (
          let i = selectedRangeLow;
          i <= selectedRangeHigh && i <= this.meta.total_pages - 1;
          i++
        ) {
          setPageItem(i);
        }
        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }
        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.meta.total_pages - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }
        // 3rd - loop thru high end of margin pages
        for (
          let i = this.meta.total_pages - 1;
          i >= this.meta.total_pages - this.marginPages;
          i--
        ) {
          setPageItem(i);
        }
      }
      return items;
    },
  },
  methods: {
    handlePageSelected(selected) {
      if (this.selected === selected) return;

      this.$emit('changePage', selected);

      this.$router.push({ query: Object.assign({}, this.$route.query, { page: selected }) });

      this.clickHandler(selected);
    },
    prevPage() {
      if (this.selected <= 1) return;

      let pageJump = this.meta.total_pages < 10 ? 1 : 5;
      let prevPage = this.selected - pageJump;
      prevPage = prevPage < 1 ? 1 : prevPage;

      this.$emit('changePage', prevPage);

      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: prevPage,
        }),
      });

      this.clickHandler(prevPage);
    },
    nextPage() {
      if (this.selected >= this.meta.total_pages) return;

      let pageJump = this.meta.total_pages < 10 ? 1 : 5;
      let nextPage = this.selected + pageJump;
      nextPage = nextPage > this.meta.total_pages ? this.meta.total_pages : nextPage;

      this.$emit('changePage', nextPage);

      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: nextPage,
        }),
      });

      this.clickHandler(nextPage);
    },
    firstPageSelected() {
      return this.selected === 1;
    },
    lastPageSelected() {
      return this.selected === this.meta.total_pages || this.meta.total_pages === 0;
    },
    selectFirstPage() {
      if (this.selected <= 1) return;
      this.$emit('changePage', 1);
      this.$router.push({ query: Object.assign({}, this.$route.query, { page: 1 }) });

      this.clickHandler(1);
    },
    selectLastPage() {
      if (this.selected >= this.meta.total_pages) return;

      this.$emit('changePage', this.meta.total_pages);

      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: this.meta.total_pages }),
      });

      this.clickHandler(this.meta.total_pages);
    },
  },
};
</script>

<style lang="css" scoped>
a {
  cursor: pointer;
}
</style>
